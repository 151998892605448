<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <navigation class="sidebar__main" :class="drawerNav == true ? 'sidebar__main_open' : ''"></navigation>
        <div :class="drawerNav == true ? 'overlay' : ''" @click="drawerNav = !drawerNav"></div>


        <v-app-bar app elevation="0" height="100" class="appBar bgNone" color="white">
            <div class="d-flex justify-space-between align-center px-5 flex-wrap w-100">
              <v-app-bar-nav-icon @click="drawerNav = !drawerNav" 
                class="hidden-md-and-up mr-3"></v-app-bar-nav-icon>
              <img class="mr-3" src="../assets/navabarIcons/coin.png" alt="">
              <div>
                <h2>Moliya</h2>
                <span>Kirim-chiqimlar</span>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex align-center">
                <v-menu offset-y content-class="elevation-1 rounded-lg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon plain v-bind="attrs" v-on="on" class="mr-2" @click="openReminder">
                      <v-badge color="blue" :content="dataReminder.length">
                        <svg width="24" height="28" viewBox="0 0 24 28" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.0201 6.91C8.71009 6.91 6.02009 9.6 6.02009 12.91V15.8C6.02009 16.41 5.76009 17.34 5.45009 17.86L4.30009 19.77C3.59009 20.95 4.08009 22.26 5.38009 22.7C9.69009 24.14 14.3401 24.14 18.6501 22.7C19.8601 22.3 20.3901 20.87 19.7301 19.77L18.5801 17.86C18.2801 17.34 18.0201 16.41 18.0201 15.8V12.91C18.0201 9.61 15.3201 6.91 12.0201 6.91Z"
                            stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                          <path
                            d="M13.8699 7.2C13.5599 7.11 13.2399 7.04 12.9099 7C11.9499 6.88 11.0299 6.95 10.1699 7.2C10.4599 6.46 11.1799 5.94 12.0199 5.94C12.8599 5.94 13.5799 6.46 13.8699 7.2Z"
                            stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                          <path
                            d="M15.02 23.06C15.02 24.71 13.67 26.06 12.02 26.06C11.2 26.06 10.44 25.72 9.90002 25.18C9.36002 24.64 9.02002 23.88 9.02002 23.06"
                            stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10"/>
                        </svg>
                      </v-badge>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group style="width: 400px; height: 300px;background: white" v-model="selected"
                                        active-class="primary--text" multiple>
                      <v-col style="background: white; flex-wrap: nowrap" @click="openReminder(item)"
                              v-for="(item,index) in reminders" :key="index">
                        <p class="font-weight-medium  pl-2 pr-2">
                          {{ item.description }}
                        </p>
                        <h6 class="ml-2">{{ item.created_at }}</h6>
                      </v-col>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </div>
        </v-app-bar>




        <v-navigation-drawer
          right
          app
          class="sidebar"
          color="white"
        >

          <div class="px-5">
            <calendar></calendar>
          </div>
          <div style="margin-top: 20px"></div>
        </v-navigation-drawer>



      </v-row>
    </v-container>
    <div class="container__main content__home">
      <router-view></router-view>
    </div>
    <v-footer app>
    </v-footer>
  </v-app>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import calendar from '../components/CalendarModal'
import navigation from '../components/NavigationDrawer'
import axios from "axios";

export default {
  name: "Home.vue",
  data() {
    return {
      selectionItem: [],
      e1: 1,
      dialog: false,
      selected: [],
      dialog1: false,
      stateSellorDebts: '',
      page: [],
      validate: false,
      items: [
        {text: 'Real-Time', icon: 'mdi-clock', bg: true},
        {text: 'Audience', icon: 'mdi-account', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
        {text: 'Real-Time', icon: 'mdi-clock', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
      ],

      disabledBtn: false,
      picker: '',
      dataReminder:{},
      reminderOpen: false,
    }
  },
  computed: {
    ...mapGetters(['routeIcons', 'reminders']),
     ...mapState({
      // drawerNav: state => state.common.drawerNav

    }),
    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },

  },
  mounted() {
    axios.get(process.env.VUE_APP_API_BASE_URL + "/reminder").then(response => {
      this.dataReminder = response.data
    });
    this.$store.dispatch('loadReminder')
  },
  methods: {
    openReminder() {
      this.reminderOpen = true;
      axios.get(process.env.VUE_APP_API_BASE_URL + "/reminder").then(response => {
        this.dataReminder = response.data;
      });
    },

    routeLinks(item) {
      this.$router.push(item.path)
    },
    sendData1() {
      this.dialog1 = false
      this.e1 = 1
    },
    sendData() {
      this.dialog = false
      this.e1 = 1
    }
  },
  components: {
    calendar, navigation
  },
}
</script>

<style scoped>


.v-text-field--outlined fieldset {
  border: 1px solid #EDF0F5;
}

.v-text-field--outlined >>> fieldset {
  border: 1px solid #EDF0F5 !important;
}

.listActiveLi {
  background: #F4F7FC;
  border-radius: 14px;
}


.activeList {
  border-radius: 14px !important;
  color: black;
}

.inputBorder {
  border: 2px solid #EDF0F5;
  box-sizing: border-box;
  border-radius: 18px;
  width: 100%;
}

.lgNavigation {
  margin-top: 1px !important;
}

.sidebar {
  background: white !important;
  width: 340px !important;
}

.avatar {
  margin-top: 40px;
}


.navigationList {
  margin-top: 5px !important;
}

.imageTitle {
  background-color: #F2F5FA;
  padding: 10px;
  border-radius: 14px;
}

.logoDiv {
  margin-top: 25px;
}

.NavigationIcon {
  border-right: 3px solid #507BE8;
}


.appBar {
  margin-right: 20px;
  background: #FCFDFF !important;
}

.searchInput {
  width: 1px;
  margin-top: 40px;
  margin-right: 40px;
}


.toolsNav {
  margin-right: 20px;

}

.v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  display: none;
}

@media (min-width: 1850px) {
  .NavigationIcon {
    border-right: 3px solid #507BE8;
  }

  .tools {
    margin-top: 50px;
  }

  .imageTitle {
    margin-top: 50px !important;
  }

  .financeLayout {
    padding: 40px;
  }

  .calendar {
    border: 1px solid #EDF0F5;
    border-radius: 10px;
    padding: 2px;
    width: 350px;
  }

  .welcome {
    margin-top: 45px !important;
  }

  .magnatInfo {
    margin-top: 55px !important;
  }

  .appBar {
    margin-right: 80px;
  }

  .buttonsCounter {
    bottom: 50px;
    margin-left: 250px !important;
  }

  .sidebar {
    background: white !important;
    width: 400px !important;
  }

  .searchInput {
    margin-top: 70px !important;
    width: 2px !important;
    margin-left: 200px;
  }

  .lgNavigation {
    margin-top: 30px !important;
  }

  .avatar {
    margin-top: 120px !important;
  }

  .navigationList {
    margin-top: 50px !important;
  }

}

@media (min-width: 1600px) {
  .NavigationIcon {
    border-right: 3px solid #507BE8;
  }

  .calendar {
    border: 1px solid #EDF0F5;
    border-radius: 10px;
    padding: 2px;
  }


  .sidebar {
    background: white !important;
    width: 350px;
  }

  .searchInput {
    margin-top: 50px;
    right: 20px;
    margin-right: 30px;
  }

  .lgNavigation {
    margin-top: 15px !important;
  }

  .avatar {
    margin-top: 80px;
  }
}
</style>
