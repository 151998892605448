<template>
  <div>
      <div>
        <img style="width: 50px;" src="../assets/magnat-logo.png" alt="" class="my-5">
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
          class="listOfNavigation"
        >
          <v-list-item
            v-for="(item, i) in routeIconsNav"
            :key="i"
            class="linksOfNavigation"
            :class="[item.active ? 'giveClassActive' : '']"
            @click="routeLinks(item)"
          >
              <img :src="item.iconUnactive" alt="">
            <v-list-item-content class="d-none">
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
      <avatar></avatar>
  </div>
</template>

<script>
import avatar from '../components/AvatarModal'
import {mapGetters} from 'vuex'

export default {
  name: "NavigationDrawer",
  computed: {
    ...mapGetters(['routeIconsNav']),
  }, 
  methods: {
    routeLinks(item) {
      this.$router.push(item.path)
      this.routeIconsNav.forEach(e => {
        e.active = false
      })
      item.active = true
    },
  },
  components: {
    avatar
  },
  data() {
    return {
      selectedItem: ''
    }
  }
}
</script>

<style scoped>
.giveClassActive {
  background: #e3f2fd;
}
</style>
