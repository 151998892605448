<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      style="position: absolute;margin-bottom: 250px;"
    >
      <template v-slot:activator="{ on, attrs }">
        <div style="border:2px solid #eee;border-radius: 1rem;padding:1rem;margin-top:2rem;">
          <v-date-picker max="40px" width="100%" v-bind="attrs" v-on="on" v-model="created_at" elevation="0"
                         class="calendar"></v-date-picker>
        </div>
      </template>
      <v-card class="pa-5">
        <h2 class="d-none">few</h2>
        <h2>Eslatma qo'shish</h2>
        <br>
        <v-textarea v-model="description" placeholder="Misol: Ertangi ishlarim" outlined></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" lass="mr-4" large elevation="0"
                 @click="addReminder"
                 style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px">
            Eslatma qo'shish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AvatarModal",
  data() {
    return {
      dialog: false,
      created_at: '',
      description: '',
      desc: {description: '', created_at: ''}
    }
  },
  watch: {
    created_at() {
      this.dialog = true
    }
  },

  mounted() {
    this.getReminderData();
  },

  methods: {
    addReminder() {
      this.dialog = false
      this.desc.description = this.description
      this.desc.created_at = this.created_at
      axios.post(process.env.VUE_APP_API_BASE_URL + "/reminder", this.desc).then(response => {
        this.getReminderData();
        this.description = "";
        this.created_at = "";
        this.dialog = true;
        return response
      });
    },

    getReminderData() {
      axios.get(process.env.VUE_APP_API_BASE_URL + "/reminder").then(response => {
        this.desc = response.data
      });
    }
  }
}
</script>

<style scoped>
.avatar {
  margin-top: 50px;
}

.v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  display: none;
}
</style>
